/*
  GroupSwitchOnOff data attributes description:
  .container
    .actions
      button.is-on data-group-switch-on-off="desc_01" data-group-switch-on-class="is-on" data-group-switch-off-class="is-off" Description 1
      button.is-off data-group-switch-on-off="desc_02" data-group-switch-on-class="is-on" data-group-switch-off-class="is-off" Description 2
    .content
      iframe.is-visible.js-desc_01 data-visibility-group="iframes" src="/desc1"
      iframe.is-hidden.js-desc_02 data-visibility-group="iframes" src="/desc2"
*/

const GroupSwitchOnOff = {
  start() {
    $('[data-group-switch-on-off]').click(this.selectFilter);
  },

  selectFilter(e) {
    e.preventDefault();
    const $selectedButton = $(e.currentTarget);
    // For the calendar switching
    $selectedButton.removeClass('is-off').addClass('is-on');
    $selectedButton.siblings().removeClass('is-on').addClass('is-off');
    // For the task switching
    const { groupSwitchOffClass, groupSwitchOnClass } = $selectedButton.data();
    $selectedButton.siblings().removeClass(groupSwitchOnClass).addClass(groupSwitchOffClass);
    $selectedButton.addClass(groupSwitchOnClass).removeClass(groupSwitchOffClass);

    const $visible = $(`.js-${$selectedButton.data('groupSwitchOnOff')}`);
    const visibilityGroup = $visible.data('visibilityGroup');
    $(`[data-visibility-group=${visibilityGroup}]`).addClass('is-hidden');
    $visible.removeClass('is-hidden');

    $selectedButton.trigger('toggle:changed');
  }
};

export default GroupSwitchOnOff;
